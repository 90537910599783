<script>
import HorizontalMenu from '@/components/general/HorizontalMenu'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import ModalContentListAssociationVue from '@/components/contents/ModalContentListAssociation.vue'
export default {
  components: {
    HorizontalMenu,
    Breadcrumbs,
    ModalContentListAssociationVue
  },
  name: 'BusinessContents',
  data () {
    return {
      selectedMenu: 0,
      showAddList: false,
      contentId: null
    }
  },
  computed: {
    menuOptions () {
      return [
        { to: { name: this.selectedWorkspace.type + '.contents.published' }, text: 'contents.menu:published.content', icon: 'mdi-folder' },
        { to: { name: this.selectedWorkspace.type + '.contents.lists' }, text: 'contents.menu:lists', icon: 'mdi-format-list-bulleted' },
        { to: { name: this.selectedWorkspace.type + '.user.contents.favorites' }, text: 'individual:saved', icon: 'mdi-heart' },
        { to: { name: this.selectedWorkspace.type + '.user.contents.in.progress' }, text: 'individual:in.progress', icon: 'mdi-play-circle' },
        { to: { name: this.selectedWorkspace.type + '.user.contents.finished' }, text: 'individual:concluded', icon: 'mdi-checkbox-marked-circle' }
      ]
    },
    breadcrumbsPaths () {
      const isListRoute = this.$route.name.includes('list')
      const path = [{ text: `main.header:${isListRoute ? 'lists' : 'contents'}`, href: this.$route.path }]
      return path
    }
  },
  methods: {
    showModalList (params) {
      this.contentId = params
      this.showAddList = !this.showAddList
    }
  }
}
</script>
<template>
  <div class="business-contents--container">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths"/>
      <h2>{{ $t('business.contents.list.page:title') }}</h2>
    </div>
    <div class="business-contents--body">
      <horizontal-menu :options="menuOptions" v-model="selectedMenu" @showModalAddListContent="params => showModalList(params)"></horizontal-menu>
      <router-view @showModalAddListContent="params => showModalList(params)"></router-view>
      <modal-content-list-association-vue v-if="showAddList" @hideModalAddListContent="showModalList()"
            :contentIdProp="contentId"></modal-content-list-association-vue>
    </div>
  </div>
</template>
<style lang="scss">
.business-contents--container {
  .business-contents--header {
    h4.business-contents--title {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #242321;
        margin-bottom: 8px;
      }
      p.business-contents--description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #7E7D77;
      }
  }
  .business-contents--body {
    text-align: left;
    .business-contents--list-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .content-card--container {
        flex: 0 0 33.333333%;
      }
    }
  }
}
</style>
